import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from "@mui/icons-material/PlayArrow"; // Import the Watch icon
import ShareIcon from "@mui/icons-material/Share"; // Import the Share icon
import Snackbar from "@mui/material/Snackbar";
import { useState } from "react";
export default function VideoMediaCard(props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleWatchClick = () => {
    window.open(props.youtubeLink, '_blank');
  };

  const handleCopyClick = () => {
    const textArea = document.createElement("textarea");
    textArea.value = props.youtubeLink;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    // Show Snackbar for a short duration
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <Card sx={{ maxWidth: 345, display: 'flex', flexDirection: 'column' }} style={{ height: "430px", padding: "10px", marginBottom: "30px" }}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image={props.img}
      />
      <CardContent style={{ flex: '1 0 auto' }}>
        <Typography gutterBottom variant="h6" component="div">
          {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography>
      </CardContent>
      <CardActions style={{ marginTop: 'auto' }}>
      <IconButton size="small" style={{ backgroundColor:"#85491f", color:"white",borderRadius: 15, padding: "10px" }} onClick={handleWatchClick}> 
          <PlayArrowIcon /> <Typography variant="caption">Watch</Typography>
        </IconButton>
        <IconButton size="small" style={{ backgroundColor:"#000327", color:"white", borderRadius: 15 , padding: "10px"}} onClick={handleCopyClick}> 
          <ShareIcon />  <Typography variant="caption">Copy video link</Typography>
        </IconButton>
      </CardActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000} // Adjust as needed
        onClose={handleSnackbarClose}
        message="Link copied to clipboard"
      />
    </Card>
  );
}
