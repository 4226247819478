import React, { useState } from "react";
import "./Home.css";
import Videos from "./constants/videos.json";
import Filter from "./Filter";
import "./Filter.css";
import VideoMediaCard from "./components/VideoMediaCard.js";
import { Grid, Typography, Container } from "@mui/material";
import Navbar from './components/NavBar.js';
function Home() {
  const [filterNiveau, setFilterNiveau] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  function handleFilterSubmit({ category, niveau, search }) {
    setFilterCategory(category);
    setFilterNiveau(niveau);
    setFilterSearch(search);
  }

  const filteredVideos = Videos.filter((item) => {
    const categoryMatch = filterCategory !== "All" ? item.categorie.includes(filterCategory) : true;
    const niveauMatch = filterNiveau !== "All" ? item.niveau.includes(filterNiveau) : true;
    const searchMatch = filterSearch !== "All" ? item.titre.toLowerCase().includes(filterSearch.toLowerCase()) : true;

    return categoryMatch && niveauMatch && searchMatch;
  });

  return (
    <>
      <Navbar>
        <Filter handleFilterSubmit={handleFilterSubmit} />
      </Navbar>
      <Container maxWidth="lg">
        <Grid container spacing={5} style={{ marginTop: "20px" }}>
          {filteredVideos.map((result, index) => (
            <Grid key={index} item xs={12} sm={4} ms={4}>
              <VideoMediaCard
                img={result.img}
                title={result.titre}
                description={result.description}
                youtubeLink={result.link}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Home;
