import "./styles.css";
import React, { useState } from "react";
import CustomNavBar from "./components/NavBar.js";
// import Header from "./Header";
import Home from "./Home";

export default function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      {/* <CustomNavBar></CustomNavBar> */}
      <Home />
    </div>
  );
}