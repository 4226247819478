import React from 'react';
import '../Navbar.css' // Make sure to import your CSS file
import Logo from '../img/logo.png'
const Navbar = ({ children }) => {
    return (
      <nav className="navbar">
        <div className="logo">
            <img src={Logo} width="100px" height="100px" />
        
        <ul className="nav-links">
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
        </div>
        {children}
      </nav>
    );
  };
  
  export default Navbar;