import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import "./Filter.css";

function Filter(props) {
  const { handleFilterSubmit } = props;
  const [filterValues, setFilterValues] = useState({
    niveau: "",
    category: "",
    search: "",
  });

  useEffect(() => {
    // This effect will run after every render

    // Call your filter function here if needed
    handleFilterSubmit(filterValues);

    // If fetchData is a function to fetch data, call it here
    // fetchData(filterValues);
  }, [filterValues, handleFilterSubmit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <form className="filterContainer">
      <div>
      <FormControl sx={{ m: 1, minWidth: 120, color: 'white' }} >
      <InputLabel
          id="category-label"
          sx={{
            color: 'white',
            '&.Mui-focused': {
              color: 'white',
            },
          }}
        >Niveau
        
        
        </InputLabel>
  <Select
    labelId="niveau-label"
    id="niveau-select"
    name="niveau"
    value={filterValues.niveau}
    label="Niveau"
    onChange={handleChange}
    sx={{
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '& .MuiSelect-icon': {
        color: 'white',
      },
      '& .MuiInputLabel-root, & .MuiInputBase-input, & .MuiSelect-root': {
        color: 'white',
      },
      '& .MuiInputBase-input:hover': {
        borderColor: 'white',
      },
      '&:hover, &:focus': {
        '& label': {
          color: 'white',
        },
        '& input': {
          color: 'white',
          borderColor: 'white',
        },
        '& fieldset': {
          borderColor: 'white',
        },
      },
    }}
  >
    <MenuItem value="bac_info">  Bac Info</MenuItem>
    <MenuItem value="bac_math">Bac Math / Science / Technique </MenuItem>
    <MenuItem value="Fac">Fac</MenuItem>
    <MenuItem value="Formation">Formation</MenuItem>
    <MenuItem value="All">All</MenuItem>
  </Select>
</FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel
          id="category-label"
          sx={{
            color: 'white',
            '&.Mui-focused': {
              color: 'white',
            },
          }}
        >
            Categorie
        </InputLabel>
        <Select
          labelId="category-label"
          id="category-select"
          name="category"
          value={filterValues.category}
          label="Categorie"
          onChange={handleChange}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '& .MuiSelect-icon': {
              color: 'white',
            },
            '& .MuiInputLabel-root, & .MuiInputBase-input, & .MuiSelect-root': {
              color: 'white',
            },
            '& .MuiInputBase-input:hover': {
              borderColor: 'white',
            },
            '&:hover, &:focus': {
              '& label': {
                color: 'white',
              },
              '& input': {
                color: 'white',
                borderColor: 'white',
              },
              '& fieldset': {
                borderColor: 'white',
              },
            },
          }}
        >
            <MenuItem value="cours">Cours</MenuItem>
            <MenuItem value="exercices">Exercices</MenuItem>
            <MenuItem value="bac_theorique">Devoirs Théoriques</MenuItem>
            <MenuItem value="bac_pratique">Devoirs Pratiques</MenuItem>
            <MenuItem value="qt_designer">QT Designer</MenuItem>
            <MenuItem value="javascript">JavaScript</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </Select>
        </FormControl>
        <TextField
  label="Search"
  name="search"
  value={filterValues.search}
  onChange={handleChange}
  sx={{
    m: 1,
    minWidth: 120,
    '& label': {
      color: 'white !important',
      '&.Mui-focused': {
        color: 'white !important',
      },
    },
    '& input': {
      color: 'white !important',
      '&:hover, &:focus': {
        borderColor: 'white !important',
      },
    },
    '& fieldset': {
      borderColor: 'white !important',
    },
  }}
/>



      </div>
    </form>
  );
}

export default Filter;
